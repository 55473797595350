import { Site } from "@/services/remote-api/user-management.service";
import {
  PaginatedUsers,
  ProductLicense,
  ResourceRoles,
  UserResourceRoles,
  UserRoles
} from "@/ui-models";
import gql from "graphql-tag";

export interface Query {
  getLicenses: ProductLicense[];
  listUsersInTenantGroup: PaginatedUsers;
  listUsersInSite: PaginatedUsers;
  listGroupsForUser: string[];
  getUserRoles: ResourceRoles[];
  getAllUserRoles: UserResourceRoles[];
  getAvailableRoles: string[];
  getUserSites: Site[];
}

export const listUsersInTenantGroup = gql`
  query ListUsersInTenantGroup($siteId: String!, $nextToken: String) {
    listUsersInTenantGroup(siteId: $siteId, nextToken: $nextToken) {
      nextToken
      users {
        email
        firstName
        lastName
        gender
        enabled
        status
        username
      }
    }
  }
`;

export const listUsersInSite = gql`
  query ListUsersInSite($siteId: String!, $nextToken: String) {
    listUsersInSite(siteId: $siteId, nextToken: $nextToken) {
      nextToken
      users {
        email
        firstName
        lastName
        gender
        enabled
        status
        username
      }
    }
  }
`;

export const listGroupsForUser = gql`
  query ListGroupsForUser($username: String!) {
    listGroupsForUser(username: $username)
  }
`;

export const getUserRoles = gql`
  query getUserRoles($siteId: String!, $userId: String!) {
    getUserRoles(siteId: $siteId, username: $userId) {
      siteId
      resourceType
      resourceId
      roles
    }
  }
`;

export const getAllUserRoles = gql`
  query getAllUserRoles($siteId: String!) {
    getAllUserRoles(siteId: $siteId) {
      userId
      siteId
      resourceType
      resourceId
      roles
    }
  }
`;

export const getAvailableRoles = gql`
  query GetAvailableRoles {
    getAvailableRoles
  }
`;

export const getUserSites = gql`
  query getUserSites {
    getUserSites {
      tenant
      id
      name
    }
  }
`;

export const getLicenses = gql`
  query getLicenses($siteId: String!) {
    getLicenses(siteId: $siteId) {
      productName
      licenseType
      expiryDate
      orderReference
      status
    }
  }
`;
