import store from "@/store";
import { ProductLicense, ResourceRoles, User, UserResourceRoles } from "@/ui-models";
import { Action, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import userManagementService, { Site } from "@remote-api/user-management.service";
import { getTenantFromGroups } from "@/services/tenant";
import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";

async function fetchUserRoles(siteId: string, userId: string) {
  return await userManagementService.getUserRoles(siteId, userId);
}

@Module({ dynamic: true, store, name: "userManagement" })
export default class UserManagementModule extends VuexModule {
  authStore: AuthModule | undefined = undefined;
  loading = false;

  private userList: User[] = [];
  currentSite: Site = { id: "", name: "" };
  private sites: Site[] = [];
  private tenant = "";
  private currentUser: User = { username: "", email: "", status: "UNKNOWN", enabled: false };
  private currentUserRoles: ResourceRoles[] = [];
  private availableRoles: string[] = [];
  private userRoles: { [userId: string]: ResourceRoles[] } = {};
  allUserRoles: { [userId: string]: ResourceRoles[] } = {};
  allUserRolesFlat: { [userId: string]: string[] } = {};

  loggedInUserAdminProducts: string[] = [];
  licenses: Array<ProductLicense> = [];

  get getCurrentUserRoles(): ResourceRoles[] {
    return this.currentUserRoles;
  }

  get getUserSites(): Site[] {
    return this.sites;
  }

  get getCurrentSite(): Site {
    return this.currentSite;
  }

  get getCurrentUser(): User {
    return this.currentUser;
  }

  get getTenant(): string {
    return this.tenant;
  }

  get getUserList(): User[] {
    return this.userList;
  }

  get getAvailableRoles(): string[] {
    return this.availableRoles;
  }

  @Mutation
  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  updateAllUserRoles(roles: UserResourceRoles[]): void {
    this.allUserRoles = {};
    this.allUserRolesFlat = {};

    roles.forEach((el) => {
      if (!(el.userId in this.allUserRoles)) {
        this.allUserRoles[el.userId] = [];
        this.allUserRolesFlat[el.userId] = [];
      }
      this.allUserRoles[el.userId] = [
        ...this.allUserRoles[el.userId],
        {
          resourceId: el.resourceId,
          resourceType: el.resourceType,
          siteId: el.siteId,
          roles: el.roles
        }
      ];
      this.allUserRolesFlat[el.userId] = [...this.allUserRolesFlat[el.userId], ...el.roles];
    });
  }

  @Action({ commit: "updateUserRoles" })
  async getUserRoles(user: User): Promise<ResourceRoles[]> {
    return (await this.fetchUserRoles({ siteId: this.currentSite.id, user: user })).roles;
  }

  @Mutation
  updateAvailableRoles(roles: string[]): void {
    this.availableRoles = roles;
  }

  @Mutation
  updateUserList(userList: User[]): void {
    this.userList = userList;
  }

  @Mutation
  updateCurrentUserRoles(userRoles: ResourceRoles[]): void {
    this.currentUserRoles = userRoles;
  }

  @Mutation
  updateUserRoles({ username, roles }: { username: string; roles: ResourceRoles[] }): void {
    this.userRoles[username] = roles;
  }

  @Mutation
  updateSites(sites: Site[]): void {
    this.sites = sites;
  }

  @Mutation
  updateTenant(tenant: string): void {
    this.tenant = tenant;
  }

  @Mutation
  updateCurrentSite(site: Site): void {
    this.currentSite = site;
  }

  @Mutation
  updateCurrentUser(user: User): void {
    this.currentUser = user;
  }

  @Mutation
  setLoggedInUserAdminProducts(products: string[]): void {
    this.loggedInUserAdminProducts = products.map((product: string) => {
      if (product == "adminPanel") return "OSDi";
      else return product;
    });
  }

  @Action({ commit: "updateCurrentSite" })
  async selectSite(site: Site): Promise<Site> {
    return site;
  }

  @Action({ commit: "updateCurrentUser" })
  async selectUser(user: User): Promise<User> {
    return user;
  }

  @Action({ commit: "updateTenant" })
  async fetchTenant(): Promise<string> {
    try {
      if (this.tenant.length > 0) return this.tenant;
      const tenantArray = await userManagementService.fetchGroupsForUser(this.userList[0].username);
      return await getTenantFromGroups(tenantArray);
    } catch (error) {
      return "";
    }
  }

  @Action({ commit: "updateSites" })
  async fetchSites(): Promise<Site[]> {
    return await userManagementService.fetchSites();
  }

  @Action({ commit: "updateUserList" })
  async fetchUserList(): Promise<User[]> {
    if (this.userList.length > 0) return this.userList;

    return await userManagementService.fetchUsersInSite(this.currentSite.id);
  }

  @Action({ commit: "updateAvailableRoles" })
  async fetchAvailableRoles(): Promise<string[]> {
    if (this.availableRoles.length > 0) return this.availableRoles;
    return await userManagementService.fetchAvailableRoles();
  }

  @Action({ commit: "updateCurrentUserRoles" })
  async fetchCurrentUserRoles(siteId: string): Promise<ResourceRoles[]> {
    return await userManagementService.getUserRoles(siteId, this.currentUser.username);
  }

  @Action({ commit: "updateUserRoles" })
  async fetchUserRoles(params: {
    siteId: string;
    user: User;
  }): Promise<{ username: string; roles: ResourceRoles[] }> {
    return {
      username: params.user.username,
      roles: await userManagementService.getUserRoles(params.siteId, params.user.username)
    };
  }

  @Action
  async fetchAllUserRoles(siteId: string): Promise<{ [userId: string]: string[] }> {
    if (Object.keys(this.allUserRoles).length > 0) return this.allUserRolesFlat;
    const userRoles = await userManagementService.getAllUserRoles(siteId);
    this.updateAllUserRoles(userRoles);
    return this.allUserRolesFlat;
  }

  @Action({ commit: "setLoggedInUserAdminProducts" })
  async fetchLoggedInUserAdminProducts(): Promise<string[]> {
    if (this.loggedInUserAdminProducts.length > 0) return this.loggedInUserAdminProducts;

    if (!this.authStore) this.authStore = getModule(AuthModule);
    const userRoles = await fetchUserRoles(this.currentSite.id, this.authStore.user!.getUsername());
    if (!userRoles) return [];
    const adminRoles = userRoles.filter((r: ResourceRoles) => {
      return r.roles.find((el) => {
        return el.includes("ProductAdmin");
      });
    });
    const adminProducts = adminRoles.map((x) => {
      return x.resourceId;
    });

    return adminProducts;
  }

  @Action
  async getLicenses(siteId: string) {
    if (this.licenses.length == 0) return await this.fetchLicenses(siteId);
    else return this.licenses;
  }

  @MutationAction({ mutate: ["licenses"] })
  async fetchLicenses(siteId: string) {
    const licenses: ProductLicense[] = await userManagementService.getLicenses(siteId);
    return { licenses: licenses };
  }

  @Mutation
  addUserRole({ user, role }: { user: User; role: string }) {
    const [product, productRole] = role.split(":");
    if (!this.allUserRoles[user.username]) this.allUserRoles[user.username] = [];
    const productRoles = this.allUserRoles[user.username].find((el) => {
      return el.siteId == this.currentSite.id && el.resourceId == product;
    });
    productRoles?.roles.push(role);

    if (!this.allUserRolesFlat[user.username]) this.allUserRolesFlat[user.username] = [];
    this.allUserRolesFlat[user.username].push(role);
  }

  @Action({ commit: "addUserRole", rawError: true })
  async addRole({ user, role, sendEmail }: { user: User; role: string; sendEmail: boolean }) {
    const tenant = this.getTenant;
    const currentSite = this.currentSite;

    const [product, productRole] = role.split(":");

    await userManagementService.addRole(
      tenant || "",
      user.username,
      {
        resourceType: "Product",
        resourceId: product,
        siteId: currentSite.id,
        role: role
      },
      sendEmail
    );

    return { user: user, role: role };
  }

  @Mutation
  deleteUserRole({ user, role }: { user: User; role: string }): void {
    const [product, productRole] = role.split(":");
    if (!this.allUserRoles[user.username]) this.allUserRoles[user.username] = [];
    const productRoles = this.allUserRoles[user.username].find((el) => {
      return el.siteId == this.currentSite.id && el.resourceId == product;
    });

    if (productRoles) {
      productRoles?.roles.splice(productRoles.roles.indexOf(role), 1);
    }

    if (!this.allUserRolesFlat[user.username]) this.allUserRolesFlat[user.username] = [];
    this.allUserRolesFlat[user.username].splice(
      this.allUserRolesFlat[user.username].indexOf(role),
      1
    );
  }

  @Action({ commit: "deleteUserRole", rawError: true })
  async deleteRole({
    user,
    role
  }: {
    user: User;
    role: string;
  }): Promise<{ user: User; role: string }> {
    const tenant = this.getTenant;
    const currentSite = this.currentSite;

    const [product, productRole] = role.split(":");

    await userManagementService.deleteRole(tenant || "", user.username, {
      resourceType: "Product",
      resourceId: product,
      siteId: currentSite.id,
      role: role
    });

    return { user: user, role: role };
  }

  @Action({ rawError: true })
  async createUser({ user }: { user: User }): Promise<boolean> {
    try {
      this.setLoading(true);
      const newUser = await userManagementService.createUserInTenantGroup(
        this.getCurrentSite.id,
        user.email,
        user.firstName || "",
        user.lastName || "",
        user.gender || "",
        user.locale || ""
      );

      if (newUser) {
        const userList = [...this.userList];
        userList.push(newUser);
        this.updateUserList(userList);
        this.setLoading(false);
        return true;
      }
    } catch (err) {
      // console.log(err);
    }
    this.setLoading(false);
    return false;
  }

  @Action({ rawError: true })
  async deleteUser({ user }: { user: User }): Promise<boolean> {
    let result = false;

    this.setLoading(true);
    try {
      result = await userManagementService.deleteUser(this.getCurrentSite.id, user.username);

      if (result) {
        const userList = [...this.userList.filter((el) => el.username !== user.username)];
        this.updateUserList(userList);
      }
    } catch (err) {
      // console.log(err);
    }
    this.setLoading(false);
    return result;
  }
}
