




import { Component, Vue, Prop } from "vue-property-decorator";
import Authenticator from "@/components/global/authentication/Authenticator.vue";
@Component({
  components: { Authenticator }
})
export default class Home extends Vue {
  @Prop()
  to!: string;
}
