import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import Vuetify from "vuetify/lib";
import globalConfig from "@/globals/Configuration";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {}
  },
  theme: {
    themes: {
      light: globalConfig.colors
    },
    options: {
      customProperties: true
    }
  }
});
