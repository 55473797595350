











import { Component, Vue } from "vue-property-decorator";
import Snackbar from "@/components/Snackbar.vue";

@Component({
  components: { Snackbar }
})
export default class App extends Vue {}
