function getDomain(): string {
  if (
    process.env.NODE_ENV === "development" &&
    window.location.hostname.indexOf("localhost") != -1
  ) {
    return "localhost";
  }
  const domainPosition = window.location.hostname.indexOf("osdi-solutions.");
  const domain = window.location.hostname.slice(domainPosition);
  return domain;
}

export class AuthStorage {
  // the promise returned from sync function
  static syncPromise: Promise<void> | null = null;

  // Given a cookie key `name`, returns the value of
  // the cookie or `null`, if the key is not found.
  static getCookie(name: string): string | null {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

  static deleteCookie(name: string): void {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    const expires = `${date.toUTCString()}`;

    // Set it
    document.cookie = `${name}=""; expires=${expires}; path=/; domain=${getDomain()}`;
  }

  // set item with the key
  static setItem(key: string, value: string): string {
    const date = new Date();
    date.setTime(date.getTime() + 60 * 24 * 60 * 60 * 1000);
    const expires = `${date.toUTCString()}`;
    document.cookie = `${key}=${value}; expires=${expires}; path=/; domain=${getDomain()}`;
    return value;
  }
  // get item with the key
  static getItem(key: string): string {
    return AuthStorage.getCookie(`${key}`) ?? "";
  }

  // remove item with the key
  static removeItem(key: string): void {
    AuthStorage.deleteCookie(key);
  }

  // clear out the storage
  static clear(): void {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
      if (name.startsWith("Cognito")) {
        AuthStorage.deleteCookie(name);
      }
    }
  }
}
