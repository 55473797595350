import awsconfig from "@/configs/aws-exports";
import globalConfig from "@/globals/Configuration";
import { getJwToken } from "@/services/authentication.service";
import AWSAppSyncClient, { AUTH_TYPE, AWSAppSyncClientOptions } from "aws-appsync";

function configureClient(apiUrl: string): AWSAppSyncClientOptions {
  return {
    url: apiUrl,
    region: awsconfig.awsAppsyncRegion,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => getJwToken()
    },
    cacheOptions: {
      addTypename: false
    },
    disableOffline: true
  };
}

export const userManagementClient = new AWSAppSyncClient(
  configureClient(globalConfig.remoteApi.API_USER_MANAGEMENT_URL)
);
