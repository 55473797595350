import AuthModule from "@/store/modules/auth";
import MainView from "@/views/MainView.vue";
import Authentication from "@/views/Authentication.vue";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { getModule } from "vuex-module-decorators";
import i18n from "@/i18n";
import logger from "@/plugins/logger";

Vue.use(VueRouter);
type Position = {
  x: number;
  y: number;
};
const routes: RouteConfig[] = [
  {
    path: "/",
    name: "AdminPanel",
    component: MainView,
    meta: {
      requireAuth: true
    },
    props: (route) => ({ embedded: !!route.query.embedded })
  },
  {
    path: "/login/:to",
    name: "Login",
    component: Authentication,
    props: true,
    beforeEnter: (to, from, next) => {
      if (window.location.href.includes("://localhost")) {
        return next();
      } else if (window.location.href.includes("dev.osdi-solutions.com"))
        location.href = `http://accounts.dev.osdi-solutions.com/login?lang=${i18n.locale.substring(
          0,
          2
        )}&redirecturi=http%3A%2F%2Fadmin.dev.osdi-solutions.com`;
      else if (window.location.href.includes("stage.osdi-solutions.com"))
        location.href = `http://accounts.stage.osdi-solutions.com/login?lang=${i18n.locale.substring(
          0,
          2
        )}&redirecturi=http%3A%2F%2Fadmin.stage.osdi-solutions.com`;
      else
        location.href = `http://accounts.osdi-solutions.com/login?lang=${i18n.locale.substring(
          0,
          2
        )}&redirecturi=http%3A%2F%2Fadmin.osdi-solutions.com`;
    }
  }
];

// eslint-disable-next-line
// @typescript-eslint/no-explicit-any
const scrollBehavior = (to: Route, from: Route, savedPosition: Position | void): any => {
  if (to.hash) {
    return { selector: to.hash };
  } else if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 };
  }
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

router.beforeEach(async (to, from, next) => {
  // Set language
  if (to.query.lang) {
    try {
      i18n.locale = to.query.lang as string;
    } catch (error) {
      logger.error("could not select language", error);
    }
  }

  // Set title
  document.title = i18n.t("global.page.title").toString();

  // Check authentication
  const { requireAuth } = to.meta!;
  if (requireAuth) {
    const authStore = getModule(AuthModule);

    await authStore.reloadUser();
    if (authStore.isLoggedIn) {
      return next();
    } else {
      const toAfterLogin = to.name;
      return next({
        name: "Login",
        params: {
          to: toAfterLogin || "/"
        }
      });
    }
  } else {
    return next();
  }
});

export default router;
