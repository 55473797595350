












import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";
import { Auth } from "@aws-amplify/auth";

@Component({
  components: {}
})
export default class TopNavigation extends Vue {
  private authstore: AuthModule = getModule(AuthModule);

  get username(): string {
    if (this.authstore) {
      const user = this.authstore.user as any;
      if (
        user.attributes!.given_name &&
        user.attributes!.family_name &&
        user.attributes!.given_name.length > 0 &&
        user.attributes!.family_name.length > 0
      ) {
        return user.attributes!.given_name + " " + user.attributes!.family_name;
      } else return user.attributes!.email ?? "";
    }
    return "";
  }

  logout() {
    this.authstore.logout();
    Auth.signOut();
    window.location.reload();
  }
}
