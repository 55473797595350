



























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class AddRoleMenu extends Vue {
  @Prop()
  roles!: string[];

  private menu = false;

  addRole(role: string): void {
    this.$emit("addRole", role);
    this.menu = false;
  }
}
