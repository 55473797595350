import "core-js/es/object";
import "core-js/es/typed-array/from";
import "isomorphic-fetch";
import store from "./store";

import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import "./plugins/amplify";
import vuetify from "./plugins/vuetify";
import router from "./router";
import * as dotenv from "dotenv";
// Vue.config.devtools = true;
dotenv.config();
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
