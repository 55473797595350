import { User } from "@/ui-models";
import gql from "graphql-tag";

export type Mutation = {
  updateUserRoles: boolean;
  resetUserPassword: boolean;
  enableUser: boolean;
  disableUser: boolean;
  createUserInTenantGroup: User;
  deleteUser: boolean;
  deleteRole: boolean;
  addRole: boolean;
};

export const enableUser = gql`
  mutation EnableUser($siteId: String!, $username: String!) {
    enableUser(siteId: $siteId, username: $username, )
  }
`;

export const disableUser = gql`
  mutation DisableUser($siteId: String!, $username: String!) {
    disableUser(siteId: $siteId, username: $username, )
  }
`;

export const updateUserRoles = gql`
  mutation UpdateUserRoles($username: String!, $roles: [UserRoles!]!) {
    updateUserRoles(username: $username, roles: $roles)
  }
`;

export const resetUserPassword = gql`
  mutation ResetUserPassword($siteId: String!, $username: String!) {
    resetUserPassword(siteId: $siteId, username: $username)
  }
`;

export const createUserInTenantGroup = gql`
  mutation CreateUserInTenantGroup(
    $siteId: String!
    $username: String!
    $firstName: String
    $lastName: String
    $gender: String
    $locale: String
  ) {
    createUserInTenantGroup(
      siteId: $siteId
      username: $username
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      locale: $locale
    ) {
      username
      email
      firstName
      lastName
      gender
      enabled
      status
    }
  }
`;

export const deleteUser = gql`
  mutation DeleteUser($siteId: String!, $username: String!) {
    deleteUser(siteId: $siteId, username: $username)
  }
`;

export const deleteRole = gql`
  mutation RemoveRoleFromUser($tenant: String!, $username: String!, $role: ItemRoleInput!) {
    removeRoleFromUser(tenant: $tenant, username: $username, role: $role)
  }
`;

export const addRole = gql`
  mutation AddRole(
    $tenant: String!
    $username: String!
    $role: ItemRoleInput!
    $sendEmail: Boolean
  ) {
    assignRoleToUser(tenant: $tenant, username: $username, role: $role, sendEmail: $sendEmail)
  }
`;
