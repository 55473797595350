

















import { Component, Prop, Vue } from "vue-property-decorator";
import { User } from "@/ui-models";

@Component({
  components: {}
})
export default class AddRoleMenu extends Vue {
  @Prop()
  user!: User;

  get name(): string {
    if (
      this.user.firstName &&
      this.user.lastName &&
      this.user.firstName.length > 0 &&
      this.user.lastName.length > 0
    ) {
      return this.user.lastName + ", " + this.user.firstName;
    } else return this.user.email;
  }
}
