














import { Component, Vue } from "vue-property-decorator";
import { ProductLicense } from "@/ui-models";
import UserManagementModule from "@/store/modules/usermanagement";
import { getModule } from "vuex-module-decorators";

@Component({
  components: {}
})
export default class InviteUsers extends Vue {
  private licenses: ProductLicense[] = [];
  private loading = false;
  private userManagementStore: UserManagementModule = getModule(UserManagementModule);

  async mounted() {
    this.loading = true;
    await this.userManagementStore.getLicenses(this.userManagementStore.getCurrentSite.id);
    this.licenses = this.userManagementStore.licenses;
    this.loading = false;
  }

  getValidDate(license: ProductLicense): string {
    return `Valid until ${new Date(license.expiryDate).toISOString().split("T")[0]}`;
  }

  getLicenseName(license: ProductLicense) {
    return license.productName + " - " + license.licenseType;
  }

  getSiteName() {
    return this.userManagementStore.getCurrentSite.name;
  }
}
