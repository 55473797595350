import * as mutations from "@/graphql/usermanagement/mutations";
import * as queries from "@/graphql/usermanagement/queries";
import { userManagementClient } from "@/plugins/graphql";
import { RemoteStatusService } from "@/services/remote-status.service";
import { ItemRole, ResourceRoles, User, UserResourceRoles, ProductLicense } from "@/ui-models";

export interface UserManagementService {
  fetchUsersInTenantGroup(siteId: string): Promise<User[]>;
  fetchGroupsForUser(username: string): Promise<string[]>;
  fetchAvailableRoles(): Promise<string[]>;
  resetUserPassword(siteId: string, username: string): Promise<boolean>;
  enableUser(siteId: string, username: string): Promise<boolean>;
  disableUser(siteId: string, username: string): Promise<boolean>;
  createUserInTenantGroup(
    siteId: string,
    username: string,
    firstName: string,
    lastName: string,
    gender: string,
    locale: string
  ): Promise<User>;
  deleteUser(siteId: string, username: string): Promise<boolean>;
  getUserRoles(site: string, username: string): Promise<ResourceRoles[]>;
  getAllUserRoles(siteId: string): Promise<UserResourceRoles[]>;
  deleteRole(tenant: string, user: string, role: ItemRole): Promise<boolean>;
  addRole(tenant: string, user: string, role: ItemRole, sendEmail: boolean): Promise<boolean>;
  fetchSites(): Promise<Site[]>;
  getLicenses(siteId: string): Promise<ProductLicense[]>;
}

export interface Site {
  id: string;
  name: string;
}

class RemoteUserManagementService implements UserManagementService {
  private readonly statusService = new RemoteStatusService();

  fetchAvailableRoles(): Promise<string[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.getAvailableRoles,
        variables: {},
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getAvailableRoles)
      .finally(this.statusService.afterRemoteCall);
  }

  fetchGroupsForUser(username: string): Promise<string[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.listGroupsForUser,
        variables: { username },
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.listGroupsForUser)
      .finally(this.statusService.afterRemoteCall);
  }

  getUserRoles(siteId: string, userId: string): Promise<ResourceRoles[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.getUserRoles,
        variables: { siteId, userId },
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getUserRoles)
      .finally(this.statusService.afterRemoteCall);
  }

  getAllUserRoles(siteId: string): Promise<UserResourceRoles[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.getAllUserRoles,
        variables: { siteId },
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getAllUserRoles)
      .finally(this.statusService.afterRemoteCall);
  }

  getLicenses(siteId: string): Promise<ProductLicense[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.getLicenses,
        variables: { siteId },
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getLicenses)
      .finally(this.statusService.afterRemoteCall);
  }

  getUserSites(): Promise<Site[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.getUserSites,
        variables: {},
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getUserSites)
      .finally(this.statusService.afterRemoteCall);
  }

  private async *fetchPaginatedUsersInTenantGroup(siteId: string) {
    let nextToken = "";
    do {
      const paginatedUsers = await userManagementClient
        .query<queries.Query>({
          query: queries.listUsersInTenantGroup,
          variables: { siteId: siteId, nextToken: nextToken },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          return {
            users: data?.listUsersInTenantGroup?.users,
            nextToken: data?.listUsersInTenantGroup?.nextToken
          };
        });
      yield paginatedUsers.users;
      nextToken = paginatedUsers.nextToken ?? "";
    } while (nextToken);
  }

  private async *fetchPaginatedUsersInSite(siteId: string) {
    let nextToken = "";
    do {
      const paginatedUsers = await userManagementClient
        .query<queries.Query>({
          query: queries.listUsersInSite,
          variables: { siteId: siteId, nextToken: nextToken },
          fetchPolicy: "no-cache"
        })
        .then(({ data }) => {
          return {
            users: data?.listUsersInSite?.users,
            nextToken: data?.listUsersInSite?.nextToken
          };
        });
      yield paginatedUsers.users;
      nextToken = paginatedUsers.nextToken ?? "";
    } while (nextToken);
  }

  async fetchUsersInTenantGroup(siteId: string): Promise<User[]> {
    this.statusService.beforeRemoteCall();
    let tenantUsers: User[] = [];
    for await (const users of this.fetchPaginatedUsersInTenantGroup(siteId)) {
      tenantUsers = [...tenantUsers, ...users];
    }
    this.statusService.afterRemoteCall();
    return tenantUsers;
  }

  async fetchUsersInSite(siteId: string): Promise<User[]> {
    this.statusService.beforeRemoteCall();
    let tenantUsers: User[] = [];
    for await (const users of this.fetchPaginatedUsersInSite(siteId)) {
      tenantUsers = [...tenantUsers, ...users];
    }
    this.statusService.afterRemoteCall();
    return tenantUsers;
  }

  async updateUserRoles(username: string, roles: string[]): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.updateUserRoles,
        variables: { username, roles }
      })
      .then(({ data }) => data?.updateUserRoles ?? false)
      .finally(this.statusService.afterRemoteCall);
  }

  async resetUserPassword(siteId: string, username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.resetUserPassword,
        variables: { siteId, username }
      })
      .then(({ data }) => data?.resetUserPassword ?? false)
      .finally(this.statusService.afterRemoteCall);
  }

  createUserInTenantGroup(
    siteId: string,
    username: string,
    firstName: string,
    lastName: string,
    gender: string,
    locale = ""
  ): Promise<User> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.createUserInTenantGroup,
        variables: { siteId, username, firstName, lastName, gender, locale }
      })
      .then(({ data }) => this.resultOrThrow(data, "createUserInTenantGroup") as User)
      .finally(this.statusService.afterRemoteCall);
  }

  enableUser(siteId: string, username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.enableUser,
        variables: { siteId, username }
      })
      .then(({ data }) => data?.enableUser ?? true)
      .finally(this.statusService.afterRemoteCall);
  }

  disableUser(siteId: string, username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.disableUser,
        variables: { siteId, username }
      })
      .then(({ data }) => data?.disableUser ?? true)
      .finally(this.statusService.afterRemoteCall);
  }

  private resultOrThrow(
    data: mutations.Mutation | null | undefined,
    operation: keyof mutations.Mutation
  ) {
    const operationType = data?.[operation];
    if (!operationType) {
      throw new Error("Empty response");
    }
    return operationType;
  }

  deleteUser(siteId: string, username: string): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.deleteUser,
        variables: { siteId, username }
      })
      .then(({ data }) => data?.deleteUser ?? true)
      .finally(this.statusService.afterRemoteCall);
  }

  deleteRole(tenant: string, username: string, role: ItemRole): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.deleteRole,
        variables: { tenant, username, role }
      })
      .then(({ data }) => data?.deleteRole ?? true)
      .finally(this.statusService.afterRemoteCall);
  }

  addRole(tenant: string, username: string, role: ItemRole, sendEmail = false): Promise<boolean> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .mutate<mutations.Mutation>({
        mutation: mutations.addRole,
        variables: { tenant, username, role, sendEmail }
      })
      .then(({ data }) => data?.addRole ?? true)
      .finally(this.statusService.afterRemoteCall);
  }
  getAvailableRoles(): Promise<string[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.getAvailableRoles,
        variables: {},
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getAvailableRoles)
      .finally(this.statusService.afterRemoteCall);
  }

  async fetchSites(): Promise<Site[]> {
    this.statusService.beforeRemoteCall();
    return userManagementClient
      .query<queries.Query>({
        query: queries.getUserSites,
        variables: {},
        fetchPolicy: "no-cache"
      })
      .then(({ data }) => data.getUserSites)
      .finally(this.statusService.afterRemoteCall);
  }
}

export default new RemoteUserManagementService();
