








































































import { Component, Vue, Watch } from "vue-property-decorator";
import UserManagementModule from "@/store/modules/usermanagement";
import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";
import { User } from "@/ui-models";
import { notification } from "@/store/store.ui-service";
import ChipContainer from "./ChipContainer.vue";
import DeleteUserOverlay from "./DeleteUserOverlay.vue";

@Component({
  components: { ChipContainer, DeleteUserOverlay }
})
export default class UserList extends Vue {
  private userManagementStore: UserManagementModule = getModule(UserManagementModule);
  private authstore: AuthModule = getModule(AuthModule);
  private searchTerm = "";
  private expanded: number | undefined = 0;
  private userRoles: { [username: string]: string[] } = {};
  private updatingRoles = false;
  private selectedFilters: number[] = [];
  private userList: User[] = [];
  private filters = ["Alva", "ConditionMonitor", "PredictiveMaintenance", "QED"];
  private emptyUser: User = { email: "", status: "UNKNOWN", username: "", enabled: false };
  private userToDelete: User = this.emptyUser;

  getName(firstName: string, lastName: string): string {
    if (firstName && lastName && firstName.length > 0 && lastName.length > 0)
      return lastName + ", " + firstName;
    if (firstName && firstName.length > 0) return firstName;
    if (lastName && lastName.length > 0) return lastName;
    else return `-- ${this.$t("nameNotAvailable")} --`;
  }

  get storeUserList() {
    return this.userManagementStore.getUserList;
  }

  get userTenant() {
    return this.userManagementStore.getTenant;
  }

  get availableRoles() {
    return this.userManagementStore.getAvailableRoles.filter((role: string) => {
      const [product, productRole] = role.split(":");
      if (
        this.userManagementStore.loggedInUserAdminProducts.includes(product) &&
        !productRole.toLowerCase().includes("admin") &&
        !product.toLowerCase().includes("admin")
      )
        return true;
      else return false;
    });
  }

  async mounted() {
    try {
      await this.userManagementStore.fetchAllUserRoles(this.userManagementStore.getUserSites[0].id);
      await this.userManagementStore.fetchUserList();
      await this.userManagementStore.fetchAvailableRoles();
      await this.userManagementStore.fetchTenant();
      this.updateUserList();
      this.updateUserRoles();
      this.expanded = 0;
    } catch (error) {
      const message = "Could not load remote users.";
      notification.notifyUser({ message, type: "error" });
    }
  }

  private allUserRoles: { [userId: string]: string[] } = {};

  updateUserRoles() {
    this.allUserRoles = this.userManagementStore.allUserRolesFlat;
    this.$emit("update");
  }

  applyFilter(userList: User[]): User[] {
    if (this.selectedFilters.length == 0) {
      return userList.filter((user: User) => {
        return (
          user.email.includes(this.searchTerm.toLowerCase()) ||
          user.firstName?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          user.lastName?.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      });
    }

    const activeFilters = this.selectedFilters.map((i: number) => {
      return this.filters[i];
    });

    return userList.filter((user) => {
      if (!(user.username in this.allUserRoles)) return false;

      return (
        this.allUserRoles[user.username].some((el: string) => {
          const [product, r] = el.split(":");
          if (activeFilters.includes(product)) return true;
          else return false;
        }) &&
        (user.email.includes(this.searchTerm.toLowerCase()) ||
          user.firstName?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          user.lastName?.toLowerCase().includes(this.searchTerm.toLowerCase()))
      );
    });
  }

  deleteDisabled(user: User) {
    if (!(user.username in this.allUserRoles)) return false;
    return this.allUserRoles[user.username].some((role: string) => {
      const [product, r] = role.split(":");
      if (
        !this.userManagementStore.loggedInUserAdminProducts.includes(product) ||
        r.toLowerCase().includes("admin")
      ) {
        return true;
      } else return false;
    });
  }

  @Watch("searchTerm")
  @Watch("storeUserList", { immediate: true })
  updateUserList() {
    this.userList = this.applyFilter(
      this.storeUserList.map((el: User) => {
        return { ...el, selected: false };
      })
    );
  }

  get loading() {
    return (
      (this.userList.length == 0 &&
        this.selectedFilters.length == 0 &&
        this.searchTerm.length == 0) ||
      Object.keys(this.allUserRoles).length == 0 ||
      this.updatingRoles ||
      this.userManagementStore.loading
    );
  }

  toggleExpand(index: number) {
    this.expanded = this.expanded != index ? index : undefined;
  }

  async deleteRole(user: User, role: string) {
    this.updatingRoles = true;

    try {
      await this.userManagementStore.deleteRole({ user, role });
      this.updateUserRoles();

      notification.notifyUser({
        message: this.$t("deleteRoleSuccess", { role, user: user.email }).toString(),
        type: "success"
      });
    } catch (error) {
      notification.notifyUser({
        message: this.$t("deleteRoleFailure", { role, user: user.email }).toString(),
        type: "error"
      });
    }

    this.updatingRoles = false;
  }

  async openConfirmDelete(user: User) {
    this.userToDelete = user;
  }

  async onCancelDelete() {
    this.userToDelete = this.emptyUser;
  }

  async deleteUser() {
    const user = this.userToDelete;

    try {
      this.userToDelete = this.emptyUser;
      if (!user) throw new Error(this.$t("error.noUserSelected").toString());
      await this.userManagementStore.deleteUser({ user });
      this.updateUserRoles();
      notification.notifyUser({
        message: this.$t("deleteUserSuccess", { user: user?.email ?? "" }).toString(),
        type: "success"
      });
    } catch (error) {
      notification.notifyUser({
        message: this.$t("deleteUserFailure", { user: user?.email ?? "" }).toString(),
        type: "error"
      });
    }
  }

  async addRole(user: User, role: string) {
    try {
      this.updatingRoles = true;
      await this.userManagementStore.addRole({ user, role, sendEmail: true });
      notification.notifyUser({
        message: this.$t("addRoleSuccess", { role, user: user.email }).toString(),
        type: "success"
      });
      this.updateUserRoles();
    } catch (error) {
      // console.log(error);
      notification.notifyUser({
        message: this.$t("addRoleFailure", { role, user: user.email }).toString(),
        type: "error"
      });
    }
    this.updatingRoles = false;
  }
}
