




























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AddRoleMenu from "./AddRoleMenu.vue";

@Component({
  components: { AddRoleMenu }
})
export default class ChipContainer extends Vue {
  private expanded = false;
  private overflowing = false;
  private rolesToAdd: string[] = [];

  @Prop()
  items!: string[];
  @Prop()
  availableRoles!: string[];
  @Prop()
  disabled!: boolean;
  @Prop()
  userTenant!: string;

  created(): void {
    this.$parent.$on("update", this.onUpdate);
    window.addEventListener("resize", this.onSizeChanged);
  }
  onSizeChanged(): void {
    this.$forceUpdate();
  }

  destroyed(): void {
    window.removeEventListener("resize", this.onSizeChanged);
  }

  onUpdate(): void {
    this.updateRolesToAdd();
    this.$forceUpdate();
  }

  onAddRole(role: string): void {
    if (!this.disabled) this.$emit("addRole", role);
  }

  onClickDelete(item: string): void {
    if (!this.disabled) this.$emit("clickDelete", item);
  }

  mounted(): void {
    this.overflowing = this.isOverflowing();
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  isOverflowing(): boolean {
    const el = this.$refs.chipContainer as HTMLElement;
    if (!el) return false;
    return el.scrollHeight > 46;
  }

  @Watch("items", { immediate: true })
  updateRolesToAdd(): void {
    //Filter to get all the available roles the logged in admin can assign to a user
    this.rolesToAdd = this.availableRoles.filter((el: string) => {
      if (this.userTenant.toLowerCase().includes("fettecompacting")) {
        return !el.toLowerCase().includes("admin");
      }
      return !(el.toLowerCase().includes("admin") || el.toLowerCase().includes("developer"));
    });
    if (!this.items) {
      return;
    }
    //Filter to exclude roles that the user already has
    this.rolesToAdd = this.rolesToAdd.filter((el) => {
      return !this.items.includes(el);
    });
  }
}
