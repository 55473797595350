export const colors = {
  success: "#4FCEB7",
  warning: "#FF8A00",
  error: "#F93E24",
  info: "#4DB6E4",
  primary: "#008B95",
  secondary: "#008B95",
  brand: "#008B95",
  brand2: "002b46",
  accent: "#DFDF00",
  teaser: "#55B9D1",
  "neutral-500": "#818A8F",
  assetStateOutdated: "#FFE0B2",
  assetStateUncertain: "#808000",
  assetStateIdle: "#78909C",
  assetStateRunning: "#2E7D32",
  assetStateHeld: "#424242",
  assetStatePaused: "#9E9E9E",
  assetStateComplete: "#ff8000",
  assetStateWash: "#0000ff",
  unitModeComputerRun: "#800080",
  unitModeTestRun: "#FFFF00",
  unitModeSetupRun: "#FFA500",
  unitModeStandardRun: "#008000"
};

export class Configuration {
  assets = {
    logo: "osdi-logo.png"
  };
  offsets = {
    header: 95,
    appHeader: 56
  };
  dimensions = {
    maxWidth: 1264
  };
  designFolder = {
    name: "design01"
  };
  notificationTimeout = 2000; //milliseconds
  colors = colors;
  smartGuide = {
    bucketName: process.env.VUE_APP_S3_BUCKETNAME_SMART_GUIDE!
  };
  customerAssets = {
    defaultVisibility: "private",
    documents: {
      folderName: "documents"
    },
    smartGuides: {
      folderName: "smartguides"
    }
  };
  date = {
    format: "dd.MM.yyyy"
  };
  remoteApi = {
    API_ASSETMANAGER_URL: process.env.VUE_APP_API_ASSETMANAGER_URL ?? "/api-assetmanager",
    API_CONDITIONMONITOR_URL:
      process.env.VUE_APP_API_CONDITIONMONITOR_URL ?? "/api-conditionmonitor",
    API_SMARTGUIDE_URL: process.env.VUE_APP_API_SMARTGUIDE_URL ?? "/api-smartguide",
    API_USER_MANAGEMENT_URL: process.env.VUE_APP_API_USER_MANAGEMENT_URL ?? "/api-user-management",
    API_LIVEGUIDE_URL: process.env.VUE_APP_LIVEGUIDE_API_URL
  };
  isDev = process.env.NODE_ENV !== "production";
}

export default new Configuration();
