
























































import { Component, Vue } from "vue-property-decorator";
import FormCard from "@/components/global/authentication/FormCard.vue";
import { Auth } from "@aws-amplify/auth";
import { VueForm } from "@/types.d";
import {
  emailValidationRules,
  passwordValidationRules,
  requiredValidationRule
} from "@/services/input-validation.service";
import logger from "@/plugins/logger";
import AuthModule from "@/store/modules/auth";
import { getModule } from "vuex-module-decorators";

@Component({
  components: { FormCard }
})
export default class ResetPassword extends Vue {
  private username = "";
  private password = "";
  private code = "";
  private valid = true;
  private error = "";
  private codeSent = false;
  private authStore = getModule(AuthModule);

  private readonly emailRules = emailValidationRules;
  private readonly passwordRules = passwordValidationRules;
  private readonly codeRules = [requiredValidationRule("Code")];

  get form(): VueForm {
    return this.$refs.form as VueForm;
  }

  requestCode() {
    if (this.form.validate()) {
      this.doRequestCode();
    }
  }

  async doRequestCode() {
    try {
      await Auth.forgotPassword(this.username.toLowerCase());
      logger.info("forgotPassword success");
      this.codeSent = true;
      this.error = "";
    } catch (error) {
      logger.error(error);
      this.codeSent = false;
      this.error = "Could not send code. Did you enter the correct e-mail?";
    }
  }

  resetPassword() {
    if (this.form.validate()) {
      this.doResetPassword();
    }
  }

  async doResetPassword() {
    try {
      await Auth.forgotPasswordSubmit(this.username.toLowerCase(), this.code, this.password);
      logger.info("forgotPasswordSubmit success");
      this.authStore.updateAuthn("signIn");
    } catch (error) {
      logger.error(error);
      this.error =
        "Could not complete verification. Did you enter the correct e-mail, code and new password?";
    }
  }
}
