
























































import { Component, Vue } from "vue-property-decorator";
import UserManagementModule from "@/store/modules/usermanagement";
import { notification } from "@/store/store.ui-service";
import { getModule } from "vuex-module-decorators";

interface UserInvite {
  firstName?: string;
  lastName?: string;
  email: string;
}

@Component({
  components: {}
})
export default class InviteUsers extends Vue {
  private userManagementStore: UserManagementModule = getModule(UserManagementModule);
  private users: UserInvite[] = [{ email: "", firstName: "", lastName: "" }];
  private loading = false;
  private valid = false;

  mounted() {
    this.valid = false;
  }

  private emailRules = [
    (v: string) =>
      !v ||
      /^\s*[\w+]+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+\s*$/.test(v) ||
      "E-mail must be valid"
  ];
  private nameRules = [(v: string) => !!v || v == "" || "Name must be set"];

  addRow() {
    this.users.push({ email: "", firstName: "", lastName: "" });
  }
  deleteRow(index: number) {
    if (this.users.length > 1) this.users.splice(index, 1);
    else this.users = [{ email: "" }];
  }

  async submit() {
    this.loading = true;
    try {
      this.users.forEach(async (u: UserInvite) => {
        await this.userManagementStore.createUser({
          user: {
            firstName: u.firstName?.trim(),
            lastName: u.lastName?.trim(),
            email: u.email.trim(),
            gender: "",
            username: "",
            status: "FORCE_CHANGE_PASSWORD",
            locale: this.$i18n.locale.substring(0, 2),
            enabled: true
          }
        });
      });

      notification.notifyUser({ message: this.$t("addUserSuccess").toString(), type: "success" });
    } catch (error) {
      notification.notifyUser({ message: this.$t("addUserFailure").toString(), type: "error" });
    }
    this.users = [{ email: "", firstName: "", lastName: "" }];
    this.loading = false;
  }
}
