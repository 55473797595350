import deDefault from "./locales/de.json";
import enDefault from "./locales/en.json";
import esDefault from "./locales/es.json";
import zhDefault from "./locales/zh.json";

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
  locale: window.navigator.language,
  fallbackLocale: "en",
  messages: {
    en: {
      ...enDefault
    },
    de: {
      ...deDefault
    },
    es: {
      ...esDefault
    },
    zh: {
      ...zhDefault
    }
  },
  silentTranslationWarn: true,
  sync: false
});
