


























import { Component, Vue, Prop } from "vue-property-decorator";
import TopNavigation from "@/components/TopNavigation.vue";
import InviteUsers from "@/components/InviteUsers.vue";
import ActiveUsers from "@/components/UserList.vue";
import Licenses from "@/components/Licenses.vue";
import AuthModule from "@/store/modules/auth";
import UserManagementModule from "@/store/modules/usermanagement";
import { getModule } from "vuex-module-decorators";

@Component({
  components: { TopNavigation, InviteUsers, Licenses, ActiveUsers }
})
export default class AdminPanel extends Vue {
  @Prop({ default: false })
  embedded!: boolean;

  private authstore: AuthModule = getModule(AuthModule);
  private userManagementStore: UserManagementModule = getModule(UserManagementModule);
  private loading = true;
  private hasPermission = false;
  private noSite = false;

  async mounted(): Promise<void> {
    this.loading = true;
    await this.userManagementStore.fetchSites();
    this.noSite = !this.userManagementStore.getUserSites[0]?.id;
    await this.userManagementStore.selectSite(this.userManagementStore.getUserSites[0]);
    await this.userManagementStore.fetchLoggedInUserAdminProducts();
    if (this.userManagementStore.loggedInUserAdminProducts.length != 0) {
      this.hasPermission = true;
    }
    this.loading = false;

    // Start sideloading for next page
    await this.userManagementStore.fetchUserList();
    await this.userManagementStore.fetchAllUserRoles(this.userManagementStore.getUserSites[0].id);
  }

  private currentTab = 0;
  private tabs = [
    {
      name: "UserInvite",
      title: this.$t("tab.inviteUsers")
    },
    {
      name: "UserList",
      title: this.$t("tab.activeUsers")
    },
    {
      name: "Licenses",
      title: this.$t("tab.licenses")
    }
  ];

  get getCurrentTab(): string {
    if (typeof this.currentTab == "undefined") return "";
    return this.tabs[this.currentTab].name;
  }

  get username(): string {
    if (this.authstore) {
      const user = this.authstore.user as any;
      if (
        user.attributes!.given_name &&
        user.attributes!.family_name &&
        user.attributes!.given_name.length > 0 &&
        user.attributes!.family_name.length > 0
      ) {
        return user.attributes!.given_name + " " + user.attributes!.family_name;
      } else return user.attributes!.email ?? "";
    }
    return "";
  }
}
